html.samsunggreat {
  .section--tips .footer {
    margin-top: 0;
  }

  .wrapper .section {
    .section__header.section__header--tips {
      background-color: #000000;

      & .section__header__title--static {
        position: static;
        transform: none;
        color: #fff;
        text-align: center;
        padding: 70px 0 0 0;

        h2,
        p {
          position: relative;
          z-index: 2;
        }

        h2 {
          margin: 0;
          font-size: 112px;
          line-height: 124px;
          padding-bottom: 60px;

          @include below(767px) {
            font-size: 80px;
            padding-bottom: 40px;
            line-height: 96px;
          }
        }

        p {
          font-size: 26px;
          line-height: 34px;
          padding-bottom: 100px;

          @include below(767px) {
            padding: 0 40px 80px;
          }
        }
      }

      .section__header__bg-image {
        display: block;
        position: absolute;
        width: auto;
        height: 175px;

        &--top {
          top: 0;
          left: 30px;
          @include below(767px) {
            left: 0;
          }
        }
        &--bottom {
          bottom: 0;
          right: 10px;
          @include below(767px) {
            right: 0;
          }
        }

        @include below(767px) {
          height: 100px;
        }
      }
    }
  }

  .tips__container {
    padding: 80px 8%;
    position: relative;
    z-index: 2;
    @include below(1023px) {
      padding: 40px 0 40px 4%;
    }
    @include below(767px) {
      padding: 40px 4%;
    }
    .tips__container__bg-image {
      display: block;
      position: absolute;
      width: auto;
      height: 175px;
      z-index: 1;

      @include below(767px) {
        display: none;
      }
      &--top {
        top: 30px;
        right: 8%;
      }
      &--bottom {
        bottom: 0;
        left: 8%;
      }
    }

    .tips__container__title {
      transition: all 0.5s ease 0.5s;
      position: relative;
      z-index: 2;
      opacity: 0;
      transform: scale(0.6);

      &.animate {
        opacity: 1;
        transform: scale(1);
      }
    }

    h2 {
      font-size: 60px;
      margin: 0 0 30px;
      line-height: 1.2;
      z-index: 3;

      @include below(767px) {
        font-size: 45px;
        padding: 0 10px;
        line-height: 1.2;
        letter-spacing: 0;
      }
    }

    p {
      font-size: 18px;
      line-height: 27px;
      font-family: $default-font-family;
      margin-bottom: 70px;
      @include below(767px) {
        font-size: 24px;
        line-height: 34px;
      }
    }

    &--black {
      color: #fff;
      background-color: #000000;
      padding-bottom: 160px;
      @include below(767px) {
        padding-bottom: 40px;
        .tip__content__title {
          color: #fff;

          + p {
            color: #fff;
          }
        }

        .tip__content__title .tips-icon {
          display: inline-block;
          width: 30px;
        }
      }
    }

    &--bg-blue {
      padding-top: 200px;
      background-image: url('../img/bg-tips-blauw.png');
      background-size: 640px;
      background-position: center 250px;
      background-repeat: no-repeat;
      transition: background-position 1s ease 0.1s;

      &.animate {
        background-position: center 50px;
      }

      @include below(767px) {
        background-image: none;
        padding-top: 80px;
      }
    }

    &--bg-purple {
      padding-top: 90px;
      background-image: url('../img/bg-tips-paars.png');
      background-size: 640px;
      background-position: center 50px;
      background-repeat: no-repeat;
      @include below(1023px) {
        background-image: none;
        padding-top: 80px;
        padding-right: 4%;
      }
    }
  }

  .tips {
    display: flex;
    flex-wrap: wrap;
  }

  .tips--spacing {
    align-items: center;
    .tip {
      margin-bottom: 70px;
      @include below(767px) {
        margin-bottom: 60px;
      }
    }
  }

  .tip {
    position: relative;
    width: calc(50% - 32px);
    margin-right: 32px;
    flex: 1 1 calc(50% - 32px);
    margin-bottom: 32px;
    z-index: 5;
    text-decoration: none;
    @include below(1023px) {
      margin-right: 0;
      width: 100%;
      flex: 1 1 100%;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .tip__content {
    position: absolute;
    padding: 0 40px;
    bottom: 24px;
    width: 100%;
    box-sizing: border-box;

    @include below(767px) {
      position: relative;
      bottom: auto;
      padding: 0;
      margin-top: 14px;
    }

    p {
      color: #fff;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.4;
      text-align: left;
      @include below(1023px) {
        margin: 0;
      }
      @include below(767px) {
        padding-top: 8px;
        color: #000;
        margin-bottom: 14px;
        padding-left: 50px;
      }
    }
  }

  .tip__content__title {
    margin: 0;
    font-size: 32px;
    color: #ffffff;
    letter-spacing: 0;
    text-transform: none;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    @include below(1200px) {
      font-size: 26px;
    }
    @include below(767px) {
      color: #000;
      margin-bottom: 0;
    }

    .tips-icon {
      display: inline-block;
      margin-right: 20px;
      @include below(767px) {
        display: none;
      }
      &.tips-icon--mobile {
        display: none;
        @include below(767px) {
          display: inline-block;
          width: 30px;
        }
      }
    }
  }

  .tip__content__play {
    position: absolute;
    bottom: 0px;
    right: 30px;
    z-index: 2;

    @include below(1200px) {
      width: 50px;
    }
    @include below(1023px) {
      width: 80px;
    }
    @include below(767px) {
      top: -100px;
      right: 25px;
      width: 60px;
    }
  }

  .tip--text {
    h2,
    p {
      text-align: left;

      @include below(1023px) {
        text-align: center;
      }
    }
    h2 {
      @include below(767px) {
        font-size: 45px;
        padding: 0 10px;
        line-height: 1.2;
        letter-spacing: 0;
      }
    }
    p {
      margin-bottom: 0;
    }

    &.tip--right {
      box-sizing: border-box;
      padding-left: 8%;
      @include below(767px) {
        padding-left: 0;
      }
    }
  }

  .tip--switch-order {
    @include below(1023px) {
      order: 2;

      + .tip {
        order: 1;
      }
    }
  }

  .tip__img {
    display: block;
    width: 100%;

    @include below(767px) {
      order: 1;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .tips__container--black,
    .tips__container--bg-blue {
      .tips__container__title {
        padding-right: 4%;
      }
    }
    .tips-tablet-slider {
      display: none;
      .slick-list {
        padding: 0 30% 0 0;
      }
      .slick-dots {
        list-style-type: none;
        text-align: right;
        padding-right: 40px;

        li {
          display: inline-block;

          button {
            text-indent: -9999px;
            outline: none;
            border: none;
            box-shadow: none;
            background-color: #d8d8d8;
            width: 20px;
            height: 15px;
            margin-left: 10px;
            padding: 0;
          }
          &.slick-active button {
            background-color: #0077cb;
          }
        }
      }
      &.slick-initialized {
        display: block;
        z-index: 9999;
      }

      .tip__content__play {
        width: 60px;
        bottom: 10px;
      }
      .tip__content {
        padding: 0 20px;
      }
      .tip {
        margin: 0 20px;
      }
    }
  }
}
