// ==========================================================================
// Base Type
// ==========================================================================

$headers: 'h1, h2, h3, h4, h5, h6';
$a-tags: 'a, a:visited';
$a-tags-hover: 'a:active, a:hover';

#{$headers} {
  text-transform: uppercase;
}

#{$a-tags} {
  color: if(variable-exists(anchor-text-color), $anchor-text-color, '#222');
}

#{$a-tags-hover} {
  color: if(
    variable-exists(anchor-text-hover-color),
    $anchor-text-hover-color,
    '#000'
  );
}

h1,
h2 {
  font-size: 100px;
  line-height: 100px;
  text-align: center;
  //font-family: $bold-font-family;
  font-family: $boldsharp-font-family;
  text-transform: none;

  @include below(1024px) {
    font-size: 75px;
    line-height: 75px;
  }

  @include below(767px) {
    font-size: 45px;
    line-height: 45px;
    padding: 0 20px;
  }
}

h1.section--title {
  margin-top: 110px;

  @include below(768px) {
    margin-top: 65px;
    font-size: 70px;
  }
}

h2 {
  font-size: 60px;
  line-height: 60px;

  @include below(1024px) {
    font-size: 45px;
    line-height: 45px;
  }

  @include below(768px) {
    font-size: 35px;
    line-height: 35px;
    padding: 0 20px;
  }
}

p {
  max-width: 660px;
  margin: auto;
  text-align: center;
  font-size: 24px;
  font-family: $boldsharp-font-family;
  line-height: 34px;
  padding: 0;

  @include below(1024px) {
    font-size: 18px;
    line-height: 22px;
  }

  @include below(768px) {
    font-size: 16px;
    line-height: 24px;
    padding: 0 10px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
