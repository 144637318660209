html.samsunggreat {
  body {
    font-family: $default-font-family;
    background-color: #fff;
  }

  .fancybox-container {
    top: 40px;
    height: calc(100% - 40px);
  }

  .fancybox-bg {
    background-color: #000;
  }
  .fancybox-is-open .fancybox-bg {
    opacity: 0.98;
  }
  .fancybox-button,
  .fancybox-infobar__body {
    background-color: #000;
  }
  &.sr .reveal {
    visibility: hidden;
  }
}

.fancybox-slide:before {
  height: 0 !important;
}
