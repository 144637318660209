html.samsunggreat {

  .section.section--home {

    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: top;

    .section--home__title--mobile {
      display: none;

      @include below(768px){
        display: block;
      }
    }

    .section--home__anim {
      width: 100%;
      height: 100%;
      //min-height: 448px;
      padding: 0 0 100px 0;
      //@include image-retina('../img/home-anim-demo', 'png', 855px, 448px);
      //background-position: center center;
      //background-repeat: no-repeat;
      //background-size: contain;
      position: relative;
      min-height: 550px;

      #home-default-desktop {
        opacity: 0;
      }

      iframe {
        width: 100%;
        height: 870px;
        position: absolute;
        left: 0;
        top: 0;
        border: 0;
        overflow: hidden;
        opacity: 0;

        &.show {
          opacity: 1;
        }
      }

      @include below(768px){
        &.section--home__anim--desktop {
          display: none;
        }

        &.section--home__anim--mobile {
          display: block !important;
        }

      }

      &.section--home__anim--mobile {
        display: none;
        background: #3f82d1;
        padding: 0;
        min-height: auto;
        height: auto;

        .section--home__anim--mobile__title {
          font-size: 70px;
          font-family: $boldsharp-font-family;
          line-height: 86px;
          text-align: center;
          color: #FFF;
          position: absolute;
          top: 25px;
          width: 500px;
          left: 50%;
          transform: translate(-50%, 0);

          @include below(520px){
            font-size: 45px;
            line-height: 50px;
            width: 300px;
          }
        }

        img {
          line-height: 0;
          display: block;

          @include below(470px){
            padding-top: 50px;
          }
        }
      }

      img {
        line-height: 0;
        width: 100%;
        margin: 0 auto;
        display: block;
      }

      &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: all 0.4s ease-out;

        &.show {
          opacity: 1;
        }

        img {
          max-width: none;
        }

      }
    }

    .section--home__title {
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 550px;
      font-family: $bold-font-family;
      opacity: 0;
      transition: all 0.3s;
      margin-top: -25px;

      @include below(768px){
        opacity: 1;
        margin-top: 0;
        position: relative;
        top: 0;
        left: 0;
        margin-top: 25px;
        margin-bottom: 25px;
      }

      &.show {
        opacity: 1;
        margin-top: 0;
      }
    }

  }

  .buttons.buttons--home {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 780px;
    text-align: center;
    @include clearfix();
    margin: 0 auto;
    position: absolute;
    top: 650px;
    max-width: 800px;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    display: none;
    transition: all 0.6s;
    margin-top: -25px;

    @include below(768px){
      display: block;
      position: relative;
      left: 0;
      transform: translate(0,0);
      top: 0;
      opacity: 1;
      margin-top: 0;
    }

    &.show {
      display: block;
    }

    &.shown {
      opacity: 1;
      margin-top: 0;
    }

    li {
      display: inline-block;
      margin-right: 10px;
      display: block;
      float: left;
      width: calc(100%/3 - 11px);
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        border-color: rgba(0,0,0,0);

        &:hover {
          border-color: rgba(0,0,0,1);
        }
      }
    }

    @include below(768px){
      width: 100%;

      li,
      li:last-child {
        width: 250px;
        display: block;
        margin: 0 auto 10px;
        float: none;
      }
    }

  }

}
