html.samsunggreat {
  header.header {
    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000;
    }

    background: #1f1e1e;
    height: 40px;

    .header-wrapper {
      max-width: 1440px;
      margin: 0 auto;
    }

    .header__samsung {
      padding: 13px 10px;
      width: 91px;
      height: 15px;
      @include image-retina('../img/header-logo', 'png', 91px, 15px);
      background-position: center center;
      background-repeat: no-repeat;
      float: left;
    }

    .header__galaxy {
      padding: 12px 10px;
      width: 111px;
      height: 17px;
      @include image-retina('../img/header-note', 'png', 111px, 17px);
      background-position: center center;
      background-repeat: no-repeat;
      float: right;
    }

    .header__menu {
      padding-left: 70px;

      @include below(768px) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: #3e3e3e;
      }

      a {
        color: #fff;
        font-size: 13px;
        line-height: 40px;
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 75px;
        display: inline-block;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          height: 0;
          width: 100%;
          background: #0077cb;
          left: 0;
          bottom: 0;
          transition: all 0.3s;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &.active {
          &:after {
            height: 3px;
          }
        }

        @include below(400px) {
          margin-right: 40px;
        }
      }
    }
  }
}
