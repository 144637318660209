html.samsunggreat {
  footer.footer {
    margin-top: 100px;

    &.rendezvous {
      margin-top: 0;
    }

    @include below(768px) {
      border-top: 0;
    }

    .footer__up {
      font-size: 11px;
      text-align: center;
      line-height: 50px;
      text-transform: uppercase;
      padding: 9px 0;
      cursor: pointer;
      border-top: 1px solid #000;
      display: none;

      @include below(768px) {
        display: block;
      }

      span {
        padding-left: 10px;
      }
    }

    .footer__copyright {
      line-height: 60px;
      font-size: 11px;
      text-align: center;
      font-family: $default-font-family;
      color: #363636;
    }

    .footer__blocks {
      display: flex;
      flex-direction: row;
      background-color: #222425;

      @include below(768px) {
        flex-direction: column;
      }
    }

    .footer__block {
      display: flex;
      flex: 1 1 50%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 50px 0 60px;
      width: 50%;
      position: relative;
      color: #ffffff;

      @include below(768px) {
        flex: 0 0 100%;
        width: 100%;

        &:first-child {
          border-bottom: 2px solid #4a4a4a;
        }
      }

      &:first-child::after {
        content: '';
        height: calc(100% - 50px);
        top: 25px;
        right: 1px;
        width: 1px;
        position: absolute;
        display: block;
        background-color: #4a4a4a;

        @include below(768px) {
          display: none;
        }
      }

      img {
        display: block;
      }

      p {
        font-family: $bold-font-family;
        font-size: 32px;
        line-height: 44px;
        color: #ffffff;
        padding: 40px 20px;
      }

      a {
        border: 1px solid #ffffff;
        border-radius: 128px;
        font-family: $bold-font-family;
        font-size: 16.64px;
        padding: 13px 35px;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 2.56px;
        line-height: 38.4px;
        transition: all 0.3s;
        &:hover {
          color: #0077cb;
          border-color: #0077cb;
        }
      }
    }

    .footer__menu {
      background: #363636;
      @include clearfix();

      @include below(768px) {
        padding: 20px 0;
      }

      ul {
        margin: 0;
        display: inline-block;
        padding: 0;

        li:first-child {
          padding-left: 80px;

          @include below(400px) {
            padding-left: 20px;
          }
        }

        li {
          display: inline-block;
          margin-right: 25px;

          a {
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 80px;
            transition: all 0.3s;
            position: relative;
            display: block;

            &:after {
              content: '';
              width: 0%;
              height: 1px;
              position: absolute;
              left: 0;
              bottom: 33px;
              background: #fff;
              transition: all 0.3s;
            }

            @include below(768px) {
              line-height: 40px;

              &:after {
                bottom: 10px;
              }
            }

            &:hover {
              &:after {
                width: 100%;
              }
            }
          }
        }

        @include below(768px) {
          width: 100%;
          text-align: center;

          li,
          li:first-child {
            padding-left: 0;
          }
        }
      }

      .country__locator {
        float: right;
        color: #fff;
        line-height: 80px;
        position: relative;
        @include clearfix();

        &:hover,
        &.show {
          .country__locator__menu {
            display: block;
          }
        }

        .country__locator__flag {
          margin-right: 13px;
          float: left;

          span {
            display: block;
            width: 24px;
            height: 16px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        .country__locator__selector {
          color: #fff;
          font-size: 11px;
          line-height: 80px;
          text-transform: uppercase;
          margin-left: 35px;
          padding-right: 80px;
          position: relative;
          transition: all 0.3s;
          cursor: pointer;

          span {
            display: inline-block;
            vertical-align: middle;
            width: 35px;
            height: 10px;
            padding-left: 10px;
            transition: all 0.3s;
          }
        }

        .country__locator__menu {
          display: none;
          position: absolute;
          width: 250px;
          bottom: 30px;
          left: 0;

          &__item {
            display: block;
            padding-bottom: 5px;
            background: #fff;
            padding: 10px;
            line-height: 25px;
            transition: all 0.3s;
            @include clearfix();

            /*
            &:hover:not(:last-child) {
              background: #0077CB;

              .country__locator__menu__item__name {
                color: #FFF;
                padding-left: 10px;
                width: calc(100% - 35px);
              }
            }
            */
            &:hover {
              background: #0077cb;

              .country__locator__menu__item__name {
                color: #fff;
                padding-left: 10px;
                width: calc(100% - 35px);
              }
            }

            &__flag {
              float: left;
            }

            &__name,
            &__name-selected {
              font-size: 11px;
              line-height: 25px;
              float: left;
              font-family: $bold-font-family;
              padding-left: 5px;
              color: #000;
              width: calc(100% - 30px);
              transition: all 0.3s;
              position: relative;

              .icon-icon-arrow-right {
                position: absolute;
                right: 0;
                top: 0;
                line-height: 25px;
              }
            }

            &__name-selected {
              color: #1428a0;
              width: 100%;

              .icon-icon-arrow-right {
                color: #000;
                margin-right: 5px;
              }
            }

            &:last-child {
              padding-top: 10px;
            }
          }
        }

        @include below(768px) {
          width: 210px;
          margin: 0 auto;
          float: none;
          transform: translate(25px, 0);

          .country__locator__selector {
            padding-right: 0;
          }
        }
      }

      .icn.icn--flag-belgium {
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        height: 17px;
        @include image-retina('../img/flag-belgium', 'png', 25px, 17px);
        background-position: center center;
        background-repeat: no-repeat;
      }

      .icn.icn--flag-netherlands {
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        height: 17px;
        @include image-retina('../img/flag-netherlands', 'png', 25px, 17px);
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}
