html.samsunggreat {
  .hide-mobile {
    @include below(768px) {
      display: none;
    }
  }

  .show-mobile {
    @include above(768px) {
      display: none;
    }
  }

  *:focus {
    outline: 0;
  }

  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  margin: 0;
  padding: 0;
  color: #000;

  body {
    margin: 0;
    padding: 0;

    &.popup {
      .content__container,
      .footer {
        display: none;
      }
    }

    @include below(768px) {
      &.home .wrapper {
        margin-top: 0px;
      }
    }
  }

  .home .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .wrapper {
    max-width: 1440px;
    margin: 0 auto 0;

    @include below(768px) {
      margin-top: 0px;
    }

    .content__container {
      //display: flex;
      flex-grow: 1;
      padding-top: 40px;
    }

    .section {
      .section__header {
        position: relative;

        img {
          display: block;
          line-height: 0;
          width: 100%;
        }

        .header--mobile {
          display: none;
        }

        @include below(768px) {
          .header--desktop {
            display: none;
          }
          .header--mobile {
            display: block !important;

            .header--mobile__background {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
            }

            .header--mobile__phone {
              max-width: 300px;
              text-align: center;
              margin: 0 auto;
              position: relative;
              padding: 50px 0 25px;

              @include below(500px) {
                max-width: 200px;
              }
            }
          }
        }

        .section__header__title {
          font-family: $bold-font-family;
          font-size: 76px;
          line-height: 84px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;

          [class^='icon-'],
          [class*=' icon-'] {
            font-size: 80px;
            line-height: 120px;
            display: block;
            text-align: center;
          }

          @include below(1024px) {
            font-size: 75px;
            line-height: 84px;
          }

          @include below(768px) {
            font-size: 70px;
            line-height: 77px;
            top: 100px;
            transform: translate(-50%, 0);
          }

          @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
            font-size: 70px;
            line-height: 77px;
            top: 100px;
            transform: translate(-50%, 0);
          }
        }

        .sub__nav {
          background: #000;

          &.sticky {
            position: fixed;
            top: 40px;
            width: 100%;
            max-width: 1440px;
            z-index: 1000;
          }

          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            @include clearfix();

            li {
              float: left;

              &:first-child {
                margin-left: 100px;
                margin-right: 15px;
              }

              a {
                color: #fff;
                text-decoration: none;
                display: block;
                line-height: 50px;
                text-transform: uppercase;
                font-size: 13px;
                margin: 0 30px;
                position: relative;

                &:after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 0;
                  background: #0077cb;
                  transition: all 0.3s;
                }

                &:hover,
                &.active {
                  &:after {
                    height: 3px;
                  }
                }
              }
            }
          }

          @include below(1024px) {
            ul li:first-child {
              margin-left: 15px;
            }
          }

          @include below(768px) {
            display: none;
            position: fixed;
            top: 80px !important;
            height: calc(100vh - 80px);
            width: 100%;
            background: #252829;
            z-index: 1000;

            &.show {
              display: block;
            }

            ul li {
              float: none;
              width: 100%;
              line-height: 50px;
              border-bottom: 1px solid #000;
              padding: 0 20px;
              margin: 0;

              &:hover,
              &.active {
                background: #3e3e3e;
              }

              a:after {
                display: none;
              }

              &:first-child {
                padding-left: 20px;
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .pane {
    position: relative;
    width: calc(90% - 160px);
    margin: 100px 5%;

    @include below(1440px) {
      width: calc(100% - 80px);
      margin: 100px 0;
      padding: 80px 40px !important;
    }

    @include below(768px) {
      margin: 50px 0;
      width: calc(100% - 40px);
      padding: 80px 20px !important;
    }

    h2 {
      padding: 0;
      margin: 0;
    }

    &.pane--gray {
      background: #f7f7f7;
      padding: 80px;
    }
  }

  span.bold {
    font-family: $bold-font-family;
  }
}

.fancybox-slide--iframe .fancybox-content {
  max-width: 1107px;
  max-height: 600px;

  @include below(768px) {
    max-height: 340px;
  }
  @include below(600px) {
    max-height: 175px;
  }
}
