html.samsunggreat {
  .section--rendezvous {
    &.hide {
      display: none;
    }

    .section__header__title {
      text-align: center;
    }
  }

  @include below(768px) {
    .section__header {
      .section__header__title {
        font-size: 50px;
        line-height: 50px;
        text-align: center;
        position: absolute;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        margin-top: -50px;
      }
    }
  }

  @include below(500px) {
    .section__header {
      .section__header__title {
        //font-size: 46px !important;
        //line-height: 46px !important;
      }
    }
  }

  .rendezvous__header {
    position: relative;
    padding: 100px 50px 0;
    background-image: url('../img/s9-left.png'), url('../img/s9-right.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 48px bottom, calc(100% + 50px) bottom;
    background-size: 289px, 401px;
    @include clearfix();

    @include below(1024px) {
      padding: 50px 40px 0;
    }

    @include below(767px) {
      padding: 0 10px;
      background-image: url('../img/s9-mobile.png');
      background-position: calc(50% + 60px) bottom;
      background-size: 420px;
      p {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__left {
      float: left;
      width: 50%;

      font-size: 100px;
      line-height: 90px;
      color: #000;
      font-family: $boldsharp-font-family;

      @include below(1024px) {
        font-size: 75px;
        line-height: 75px;
      }

      @include below(767px) {
        font-size: 73px;
        line-height: 72px;
        margin-bottom: 50px;
      }

      @include below(500px) {
        font-size: 45px;
        line-height: 45px;
        margin-bottom: 50px;
      }
    }

    &__right {
      float: left;
      width: 50%;
      text-align: left;

      p {
        text-align: left;
        margin-bottom: 25px;

        a {
          color: #000;
        }

        span.bold {
          font-family: $bold-font-family;
        }
        span.small {
          font-size: 18px;
          line-height: 28px;

          @include below(1024px) {
            font-size: 13px;
            line-height: 28px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @include below(767px) {
      &__left,
      &__right {
        float: none;
        width: 100%;
        text-align: center;

        p {
          text-align: center;
          font-size: 16px;

          span.small {
            font-size: 11px !important;
          }
        }

        br {
          display: none;

          &.break-mobile {
            display: block;
          }
        }
      }
    }
  }

  .rendezvous__popup__close .button.button--ghost.button--ghost--small {
    padding: 0 5px;
    line-height: 40px;
  }

  .rendezvous__code {
    position: absolute;
    width: 460px;
    background: #f7f7f7;
    padding: 50px 25px 25px 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 9px 7px 60px -7px rgba(0, 0, 0, 0.57);
    display: none;

    @include below(600px) {
      width: calc(90% - 50px);
      left: 5%;
      transform: translate(0, -50%);
      padding: 25px;
    }

    &.show {
      display: block;
    }

    &__close {
      position: absolute;
      right: 15px;
      top: 15px;
      border: 1px solid #000;
      font-size: 18px;
      font-family: $bold-font-family;
      line-height: 30px;
      width: 30px;
      border-radius: 15px;
      text-align: center;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    &__title {
      font-size: 35px;
      font-family: $bold-font-family;
      line-height: 40px;
      margin-bottom: 25px;
    }

    &__description {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 25px;

      p.imei-confirmation {
        text-align: left;
        font-size: 18px;
        border-top: 1px solid black;
      }
    }

    &__form {
    }
  }

  .rendezvous__list {
    position: relative;
    padding: 50px;
    margin-bottom: 85px;
    background-color: #f7f7f7;
    @include clearfix();
    @include below(1024px) {
      padding: 45px 16px;
    }
    @include below(767px) {
      padding: 25px 4%;
    }

    &.show {
      background-position: center bottom;
    }

    ul {
      padding: 0;
      margin: 0;
      padding-left: 50px;
      @include below(1024px) {
        padding-left: 40px;
      }
      @include below(767px) {
        padding-left: 30px;
        vertical-align: top;
      }
      li {
        padding: 5px 0;
      }
    }

    &__title {
      font-family: $boldsharp-font-family;
      font-size: 55px;
      color: #000000;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 20px;
      line-height: 80px;

      @include below(767px) {
        font-size: 40px;
        padding: 0;
      }
    }

    &__subtitle {
      color: #000;
      text-transform: uppercase;
      text-align: center;
      font-size: 18px;
      line-height: 22px;

      @include below(767px) {
        text-transform: none;
        font-size: 20px;
        line-height: 1.5;
      }
    }

    &__container {
      margin-top: 100px;
      text-align: center;
      @include below(767px) {
        margin-top: 40px;
      }
      @include clearfix();
    }

    &__container.rendezvous__list__container--by-2 {
      .rendezvous__list__item {
        width: calc(100%/2 - 25px);
      }
    }

    &__item {
      display: inline-block !important;
      vertical-align: top;
      width: calc(100%/3 - 25px);
      margin-right: 25px;
      background-color: #fff;
      min-height: 100px;
      position: relative;
      padding: 50px 0;
      color: #fff;
      text-align: left;

      @include below(767px) {
        width: 100%;
        display: block;
        margin-right: 0;
        margin-bottom: 25px;
        padding: 0;
        background-color: transparent;

        > div:last-child {
          width: calc(100% - 53px);
          float: right;
          background-color: #fff;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 90px;
        height: 45px;
        background: url('../img/oval.svg') center center no-repeat;
        background-size: contain;
        top: -45px;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0.7;

        @include below(767px) {
          display: none;
        }
      }

      &__button {
        margin-top: 25px;
        text-align: center;

        .button {
          background-color: #0077cb;
          color: #fff;
          font-family: $bold-font-family;
          max-width: 250px;
          height: 60px;
          font-size: 13px;
          display: block;
          margin: 0 25px;
          text-align: center;
          &:hover {
            background-color: darken(#0077cb, 5%);
          }
          @include below(1024px) {
            margin: 0 25px;
          }

          @include below(767px) {
            margin: 0 auto;
          }
        }
      }

      @include below(900px) {
        .button {
          padding: 0 5px;
        }
      }

      @include below(767px) {
        display: none;
      }

      &:last-child {
        margin-right: 0;
      }

      &__icon {
        @include below(767px) {
          position: absolute;
          background-color: white;
          width: 115px;
          border-right: 115px;
          border-radius: 50%;
          height: 124px;
        }
      }

      &__icon img {
        position: absolute;
        left: 50%;
        top: 0;
        width: 70px;
        transform: translate(-50%, -50%);
        border-radius: 40px;
        opacity: 0.7;

        @include below(767px) {
          transform: none;
          position: relative;
          top: 27px;
          left: 20px;
        }
      }

      &__title {
        font-size: 24px;
        font-family: $boldsharp-font-family;
        line-height: 32px;
        padding: 25px 20px 10px;
        text-align: center;
        color: #000;

        @include below(767px) {
          text-align: left;
          font-size: 21px;
          position: relative;
          line-height: 1.4;
          padding: 15px 5px 15px 60px;
          height: 66px;
          .open & {
            padding: 15px 5px 15px 60px;
          }
        }
      }

      &__description {
        padding: 20px;
        min-height: 285px;

        @include below(1024px) {
          padding: 0;
        }

        @include below(767px) {
          padding: 0 20px 0 50px;
          min-height: 0;
        }

        p {
          font-family: $default-font-family;
          color: #000;
          text-align: left;
          padding: 25px 20px 25px 25px;
          font-size: 16px;
          line-height: 24px;

          @include below(1024px) {
            padding: 10px 20px;
            font-size: 16px;
            line-height: 25px;
          }

          @include below(767px) {
            padding: 10px;
            font-size: 14px;
          }

          &.bold {
            font-family: $bold-font-family;
          }

          &.center {
            text-align: center;
          }
        }

        ul li {
          color: #000;
          font-family: $default-font-family;
          font-size: 16px;
          line-height: 24px;
          padding-right: 30px;
          @include below(1024px) {
            font-size: 16px;
            line-height: 1.3;
          }
          @include below(767px) {
            font-size: 14px;
            line-height: 1.3;
          }
        }
      }
    }

    .rendezvous__list__container__mobile {
      margin: 50px 0 0;
      display: none;

      @include below(768px) {
        display: block;
      }

      .button {
        display: block;
        margin: auto;
        margin-bottom: 25px;
        border-radius: 100px;

        [class^='icon-'],
        [class*=' icon-'] {
          font-size: 72px;
          line-height: 72px;
          width: 72px;
          height: 72px;
          padding: 10px 0;

          @include below(500px) {
            font-size: 50px;
            line-height: 50px;
            width: 50px;
            height: 50px;
          }
        }

        .button__content {
          font-size: 32px;
          line-height: 34px;

          @include below(500px) {
            font-size: 22px;
            line-height: 24px;
          }
        }
      }
    }

    .rendezvous__list__phone {
      text-align: center;
      padding: 150px 0;
      position: relative;

      @include below(768px) {
        display: none;
      }

      img {
        width: 100%;
        max-width: 888px;
      }

      .rendezvous__phone--background {
        opacity: 0;
      }

      .rendezvous__phone--anim {
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 888px;
        transform: translate(-50%, -50%);
        margin-top: 200px;
        opacity: 0;
        transition: all 1s 1s ease-out;

        &.show {
          opacity: 1;
          margin-top: 0;
        }
      }
    }
  }

  .rendezvous__popup {
    position: relative;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: none;
    margin-top: 80px;

    &.show {
      display: block;
    }

    &__close {
      text-align: right;

      .button {
        font-family: $default-font-family;

        span {
          font-family: $bold-font-family;
          padding-left: 10px;
        }
      }
    }

    &__header {
      padding: 25px;
      background: #fff;
      position: relative;
      @include clearfix();

      @include below(767px) {
        padding: 25px 10px;
      }

      &__left {
        text-align: center;
        font-size: 60px;
        font-family: $boldsharp-font-family;
        line-height: 1.2;

        &__content {
          padding: 25px 40px 50px;
        }
      }

      &__right {
        float: left;
        width: 50%;
        font-size: 18px;
        line-height: 30px;

        &__content {
          padding: 50px 0 50px 10px;
          max-width: 295px;
        }

        .bold {
          font-family: $bold-font-family;
        }
      }

      @include below(768px) {
        &__left {
          float: none;
          width: 100%;
          text-align: center;

          &__content {
            margin: 0 auto;
            padding-right: 0;
            font-size: 45px;
            line-height: 45px;
            padding: 25px;
          }
        }

        &__right {
          float: none;
          width: 100%;
          text-align: center;

          &__content {
            margin: 0 auto;
            padding: 0;
            max-width: none;
          }
        }
      }
    }

    &__steps {
      border-top: 1px solid #f8f8f8;
      padding: 10px 0;
      text-align: center;
    }

    &__step {
      font-family: $bold-font-family;
      display: inline-block;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-right: 35px;

      @include below(768px) {
        > span {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &__number {
        display: inline-block;
        vertical-align: middle;
        background: #000;
        color: #fff;
        border-radius: 50%;
        width: 22px;
        height: 20px;
        line-height: 20px;
        font-size: 11px;
        padding-top: 2px;
        border: 1px solid #000;
        text-align: center;
        margin-right: 10px;

        span {
          position: relative;
          left: 1px;
        }
      }

      &.not--active {
        color: #d8d8d8;

        .rendezvous__popup__step__number {
          border: 1px solid #d8d8d8;
          background: #fff;
          border-color: #d8d8d8;
          color: #d8d8d8;
        }
      }
    }

    &__form {
      background: #f8f8f8;
      position: relative;

      &__title {
        text-align: center;
        font-size: 28px;
        line-height: 60px;
        padding: 50px 0 20px;

        p {
          font-family: $default-font-family;
          font-size: 18px;
          margin-top: 15px;
        }
      }

      &__content {
        max-width: 620px;
        margin: 0 auto;
        padding: 25px 0;
        display: none;

        @include below(768px) {
          padding: 25px;
        }

        &.show {
          display: block;
        }
      }

      &__cta {
        text-align: center;
        margin: 50px 0;

        .button {
          min-width: 250px;
        }
      }
    }

    .input--calendar__timeslots {
      @include clearfix();

      column-gap: 2;
      column-count: 2;

      &.input--calendar__timeslots--no-gap {
        column-gap: 1;
        column-count: 1;
      }

      @include below(768px) {
        column-gap: 1;
        column-count: 1;
      }

      /*
      .left {
        float: left;
        width: calc(50% - 10px);
        margin-right: 10px;
      }

      .right {
        float: left;
        width: calc(50% - 10px);
        margin-left: 10px;
      }
      */
    }

    .slots--exactdate {
      font-size: 16px;
      text-align: left;
    }

    .calendar {
      background: #fff;
      padding: 18px;
      border-radius: 5px;

      ul.calendar__months {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: center;
        @include clearfix();

        li {
          display: inline-block;
          vertical-align: middle;

          span {
            display: block;
            font-size: 13px;
            line-height: 22px;
            padding: 0 10px;
            background: #f8f8f8;
            color: #3482cb;
            border-radius: 10px;
            margin-right: 5px;
            text-transform: lowercase;
            transition: all 0.3s;
            cursor: pointer;

            &:hover,
            &.selected {
              background: #3482cb;
              color: #fff;
            }
          }
        }
      }

      ul.calendar__days-header {
        padding: 0;
        margin: 15px 0;
        list-style-type: none;
        @include clearfix();

        li {
          float: left;
          width: calc(100% / 7);
          font-size: 12px;
          text-align: center;
        }
      }

      ul.calendar__days {
        padding: 0;
        margin: 0;
        list-style-type: none;
        @include clearfix();

        li {
          float: left;
          width: calc(100% / 7);
          text-align: center;
          margin: 7px 0px;

          span {
            padding: 5px;
            font-size: 16px;
            color: #d3d3d3;

            &.selectable {
              color: #222222;
              cursor: pointer;
            }

            &.selected {
              color: #fff;
              background: #3482cb;
              border-radius: 15px;
            }
          }

          &.calendar__days__offset-1 {
            width: calc(100% / 7 * 1);
          }

          &.calendar__days__offset-2 {
            width: calc(100% / 7 * 2);
          }

          &.calendar__days__offset-3 {
            width: calc(100% / 7 * 3);
          }

          &.calendar__days__offset-4 {
            width: calc(100% / 7 * 4);
          }

          &.calendar__days__offset-5 {
            width: calc(100% / 7 * 5);
          }

          &.calendar__days__offset-6 {
            width: calc(100% / 7 * 6);
          }
        }
      }
    }
  }

  .input--country {
    position: relative;
    width: 288px;
    height: 341px;
    display: block;
    margin: 50px auto;

    img#country {
    }

    .input--country__marker {
      position: absolute;
      cursor: pointer;
    }

    .input--country__info {
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      padding: 15px;
      font-size: 13px;
      line-height: 18px;
      width: 200px;
      display: none;
      transform: translate(0, -50%);

      &.show {
        display: block;
      }

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: #fff;
        transform: rotate(45deg) translate(0, -50%);
        top: 50%;
        right: 0px;
      }

      &__marker,
      &__content {
        display: inline-block;
        vertical-align: top;
      }

      &__content {
        padding-left: 20px;
      }

      span {
        display: block;
        margin-bottom: 15px;
      }

      .input--country__info__choose {
        color: #0077cb;
        text-decoration: underline;
      }
    }
  }

  &[lang='nl-nl'] {
  }

  .rendezvous__popup__home__form__4 {
    padding: 0 5% 200px;
    max-width: none;
    .rendezvous__popup__home__form__4__body {
      /* We hebben een nieutw: */
      font-family: $default-font-family;
      font-size: 24px;
      color: #000000;
      padding: 30px 20px 80px;
      text-align: center;
      line-height: 1.5;
    }
  }

  footer.footer.rendezvous__complete__footer {
    .footer__up {
      display: none !important;
    }

    .footer__blocks {
      background-color: white;

      .footer__block {
        &,
        p,
        a {
          color: #000;
        }

        a {
          border-color: #000;
        }
      }
    }
  }
}

.sessions__title {
  text-align: center;
  h1 {
    position: relative;
    font-family: $boldsharp-font-family;
    font-size: 112px;
    color: #000;
    display: inline-block;
  }

  img {
    width: 160px;
    position: absolute;
    left: 78px;
    top: -28px;
  }

  @include below(767px) {
    margin-top: 40px;
    h1 {
      font-size: 70px;
      text-align: center;
    }
    img {
      width: 100px;
      left: 70px;
    }
  }
}

.sessions__subtitle {
  font-family: $boldsharp-font-family;
  font-size: 26px;
  color: #363636;
  text-align: center;
  line-height: 34px;
  max-width: 700px;
  margin: 0 auto 230px;
  @include below(1320px) {
    margin-bottom: 92px;
  }
  @include below(767px) {
    margin-bottom: 40px;
    padding: 0 20px;
    font-size: 26px;
    color: #000000;
    text-align: center;
    line-height: 34px;
  }
}

.sessions__cta {
  text-align: center;
  margin-bottom: 35px;
  @include below(1320px) {
    margin-bottom: 400px;
  }
  @include below(1024px) {
    margin-bottom: 560px;
  }
  @include below(767px) {
    margin-bottom: 335px;
  }
  cursor: pointer;
  small {
    /* Haal alles uit je: */
    font-family: $default-font-family;
    font-size: 11px;
    color: #363636;
    letter-spacing: 0;
    text-align: center;
    position: relative;
    top: 4px;
    left: -55px;

    @include below(767px) {
      display: none;
    }
  }

  span {
    display: block;
    font-size: 26px;
    font-family: $boldsharp-font-family;

    @include below(767px) {
      display: none;
    }
  }

  .icon-angle-down {
    width: 12px;
    margin-top: 20px;
    animation-name: jump;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(3px);
  }

  50% {
    transform: translateX(0);
  }
}

.open.rendezvous__list__item {
  > div:last-child {
    padding-bottom: 25px;
  }
}

.rendezvous__list__toggler {
  @include below(767px) {
    display: none;

    .open & {
      display: block;
    }
  }
}

.rendezvous__list__item__more {
  display: none;
  @include below(767px) {
    display: block;
    cursor: pointer;
    color: #000;
    text-decoration: underline;
    padding: 0px 10px 10px 60px;

    span {
      &.hide {
        display: none;
      }
    }
    img {
      width: 12px;
      margin-left: 15px;
      transition: transform 0.2s ease;
    }

    .open & img {
      transform: rotate(180deg);
    }
  }
}

.calendar__days__day {
  width: 26px;
  height: 26px;
  display: inline-block;
  padding: 0 !important;
  border-radius: 50%;
}

.calendar__days__day--inner {
  font-style: normal;
  position: relative;
  top: 3px;
}
