html.samsunggreat {
  .section--transfer {
    .transfer__subtitle {
      color: #363636;
      @include below(1024px) {
        font-size: 26px;
        line-height: 1.4;
      }
      @include below(768px) {
        font-size: 20px;
        line-height: 1.4;
      }
    }
  }
  .transfer__way--container {
    margin-top: 100px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    @include clearfix();

    @include below(767px) {
      margin: 25px 0 0 0;
      display: block;

      .slick-list {
        padding: 0 30% 0 0;
      }
    }

    .transfer__way__item {
      box-sizing: border-box;
      float: left;
      width: calc(100%/3);
      background-color: #f7f7f7;
      margin-right: 34px;
      text-align: center;
      padding: 0 40px 70px;
      text-decoration: none;

      @include below(1024px) {
        padding: 0 30px 50px;
      }

      @include below(900px) {
        margin-right: 10px;
      }

      @include below(767px) {
        padding: 0 20px 50px;
        margin: 0 20px;
      }

      img {
        width: 100%;
        max-width: 320px;
        margin: auto;
      }

      &:last-child {
        margin-right: 0;
      }

      .transfer__way__item__content {
        font-size: 35px;
        line-height: 35px;
        margin-top: 50px;
        margin-bottom: 10px;
        text-decoration: none;

        @include below(768px) {
          margin-top: 20px;
          font-size: 20px;
          line-height: 24px;
        }

        p {
          font-family: $default-font-family;
          display: block;
          font-size: 18px;
          line-height: 24px;
          margin-top: 15px;
          padding: 0;

          @include below(768px) {
            font-size: 16px;
          }

          a {
            color: #000;
          }
        }
      }
    }

    .slick-dots {
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;
      margin-top: 40px;

      li {
        display: inline-block;
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 17px;
          height: 17px;
          border-radius: 12px;
          background: #d8d8d8;
          margin-right: 13px;
          transition: all 0.3s;
        }

        button {
          display: none;
        }

        &.slick-active,
        &:hover {
          &:before {
            color: #0077cb;
            background: #0077cb;
          }
        }

        &:focus {
          outline: 0;
        }
      }
    }
  }

  .transfer__way__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;

    a {
      display: inline-block;
      margin-right: 32px;
      color: #363636;
      letter-spacing: 0;

      &:last-child {
        margin-right: 0;
      }

      @include below(400px) {
        max-width: 120px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .transfer__video--container {
    width: 90%;
    margin: 130px 5% 130px;
    position: relative;
    @include below(1023px) {
      display: none;
    }

    .transfer__video--container__player {
      display: none;
    }

    &.transfer__video--container-mobile {
      display: none;
      margin: 130px 0 0 5%;
      width: 95%;

      @include below(767px) {
        margin: 100px 4% 0;
        width: auto;
      }
      h2 {
        font-size: 40px;
        line-height: 1.2;
      }

      .js-video {
        text-decoration: none;
      }

      @include below(1023px) {
        display: block;

        .icon-icon-play {
          right: 20px;
          bottom: 0px;
          font-size: 60px;
          transform: none;
          left: auto;
          top: auto;
        }

        .slider {
          .slick-list {
            padding: 0 30% 0 0;
          }
          .slick-slide {
            margin: 0 20px;
          }
          .slick-dots {
            list-style-type: none;
            text-align: right;
            padding-right: 40px;

            li {
              display: inline-block;

              button {
                text-indent: -9999px;
                outline: none;
                border: none;
                box-shadow: none;
                background-color: #d8d8d8;
                width: 20px;
                height: 15px;
                margin-left: 10px;
                padding: 0;
              }
              &.slick-active button {
                background-color: #0077cb;
              }
            }
          }
        }
        .transfer__video--container__player {
          display: block;
        }
        .transfer__video--container__menu__item {
          background-color: transparent;
          width: 100%;
          &:after {
            content: none;
          }
          div {
            color: #000;
            display: block;
            margin: 0;
            font-family: $boldsharp-font-family;
            font-size: 26px;
            margin-right: 50px;

            @include below(1024px) {
              margin-bottom: 30px;
            }

            @include below(768px) {
              margin-bottom: 90px;
            }

            br {
              display: none;
            }
          }
        }
      }

      @include below(768px) {
        .icon-icon-play {
          font-size: 50px;
          right: 20px;
          bottom: 0px;
        }
      }
    }

    &__player {
      position: relative;
      overflow: hidden;
      display: none;
      &.show {
        display: block;
      }
      @include below(768px) {
        display: none;
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: 100;
      }

      .icon-icon-play {
        font-size: 82px;
        line-height: 82px;
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &__menu {
      @include clearfix();

      &__item {
        cursor: pointer;
        background: #222425;
        float: left;
        width: calc(100%/3);
        transition: all 0.3s;
        text-decoration: none;
        line-height: 80px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -26px;
          height: 52px;
          width: 1px;
          background: #4a4a4a;
          display: block;
        }

        &:last-child {
          border-color: #222425;

          &:after {
            content: normal;
          }
        }

        div {
          font-family: $boldsharp-font-family;
          vertical-align: middle;
          font-size: 18px;
          color: #ffffff;
          display: inline-block;
          transition: all 0.3s;
          line-height: normal;

          &:first-child {
            margin: 0 20px;
            padding-bottom: 5px;
          }
        }

        .icon-icon-play {
          float: right;
          font-size: 48px;
          margin-right: 18px;
          line-height: 80px;
          color: #fff;
        }

        @include below(980px) {
          div {
            font-size: 16px;

            &:first-child {
              margin: 0 10px;
            }
          }

          .icon-icon-play {
            font-size: 32px;
            margin-right: 16px;
          }
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;
        }

        &.active,
        &:hover {
          div {
            color: #0077cb;
          }

          .icon-icon-play {
            color: #0077cb;
          }
        }

        @include below(840px) {
          div {
            font-size: 15px;
          }
        }

        @include below(768px) {
          float: none;
          width: 100%;
          max-width: none;
          margin: 10px auto !important;
          display: block;

          div {
            font-size: 19px;
          }

          .icon-icon-play-filled {
            font-size: 50px;
          }
        }
      }
    }
  }

  .transfer__need-help {
    margin: 150px 0;
    text-align: center;

    &__title {
      font-size: 35px;
      line-height: 50px;
      margin-bottom: 25px;
      text-align: center;
    }

    .button {
      display: inline-block;
    }

    @include below(1024px) {
      margin: 75px 0;
    }
  }
}
