$textfields: 'input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"]';
$textfields-focus: 'input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus';
$textfields-hover: 'input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover';
$textfields-active: 'input[type="color"]:active, input[type="date"]:active, input[type="datetime"]:active, input[type="datetime-local"]:active, input[type="email"]:active, input[type="month"]:active, input[type="number"]:active, input[type="password"]:active, input[type="search"]:active, input[type="tel"]:active, input[type="text"]:active, input[type="time"]:active, input[type="url"]:active, input[type="week"]:active';
$textfields-disabled: 'input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled';

$textinputs: $textfields + ', textarea';
$textinputs: $textfields-focus + ', textarea:focus';
$textinputs: $textfields-hover + ', textarea:hover';
$textinputs: $textfields-active + ', textarea:active';
$textinputs: $textfields-disabled + ', textarea:disabled';

$buttons: 'input[type="submit"], input[type="button"], input[type="reset"], button';
$buttons-focus: 'input[type="submit"]:focus, input[type="button"]:focus, input[type="reset"]:focus, button:focus';
$buttons-hover: 'input[type="submit"]:hover, input[type="button"]:hover, input[type="reset"]:hover, button:hover';
$buttons-active: 'input[type="submit"]:active, input[type="button"]:active, input[type="reset"]:active, button:active';
$buttons-disabled: 'input[type="submit"]:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, button:disabled';

#{$buttons} {
  foo: bar;
}

#{$textfields} {
  foo: bar;
}

#{$textinputs} {
  foo: bar;
}

html.samsunggreat {
  .input--group {
    position: relative;
    margin-bottom: 20px;

    label {
      display: block;
      font-size: 13px;
      font-family: $bold-font-family;
      margin-bottom: 10px;
      text-transform: uppercase;

      a {
        color: #000;
      }
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'] {
      display: block;
      line-height: 50px;
      font-size: 13px;
      border: 1px solid #fff;
      padding: 0 15px;
      width: calc(100% - 30px);
      transition: all 0.3s;

      @include placeholder {
      }

      &:focus {
        outline: 0;
        border-color: #0077cb;
      }
    }

    .message {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 13px;
      color: #0077cb;

      @include below(768px) {
        position: relative;
        margin-bottom: 5px;
      }
    }

    input[type='submit'] {
      border: 1px solid #000;
      border-radius: 100px;
      text-align: center;
      line-height: 60px;
      padding: 0 40px;
      font-size: 13px;
      text-transform: uppercase;
      transition: all 0.3s;
      background: none;
      cursor: pointer;

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    &.valid {
      input[type='text'],
      input[type='email'],
      input[type='password'],
      input[type='tel'] {
        border-color: #69d552;
      }
    }

    &.error {
      input[type='text'],
      input[type='email'],
      input[type='password'],
      input[type='tel'] {
        border-color: #ff4337;
      }

      .message {
        display: block;
        color: #ff4337;
      }
    }

    &.input--group--center {
      text-align: center;
    }

    .input__help {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 13px;
      bottom: 12px;
      right: 10px;
      font-size: 16px;
      color: #fff;
      line-height: 26px;
      text-align: center;
      background: #0077cb;
      cursor: pointer;

      &:hover {
        .input__help__box {
          display: block;
        }
      }
    }

    .input__help__box {
      position: absolute;
      //display: none;
      padding: 10px;
      background: #fff;
      font-size: 11px;
      line-height: 15px;
      color: #0077cb;
      border: 1px solid #0077cb;
      width: 260px;
      bottom: 40px;
      left: -130px;
      text-align: left;
      display: none;

      &:after {
        content: '';
        width: 13px;
        height: 13px;
        transform: rotate(45deg) translate(-50%, 0);
        display: block;
        position: absolute;
        left: 50%;
        bottom: -13px;
        background: #fff;
        border-right: 1px solid #0077cb;
        border-bottom: 1px solid #0077cb;
      }

      @include below(768px) {
        left: auto;
        right: -25px;

        &:after {
          left: auto;
          right: 25px;
        }
      }
    }

    &.input--group--blue {
      margin-bottom: 70px;

      input[type='text'],
      input[type='email'],
      input[type='password'],
      input[type='tel'] {
        background: #3482cb;
        color: #fff;
      }

      .input__help {
        background: #fff;
        color: #3482cb;
      }
    }

    &.input--group--radio {
      margin-bottom: 0;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      input[type='radio'] {
        width: 20px;
        height: 20px;
        position: absolute;
        left: -99999px;
      }

      label {
        position: relative;
        padding-left: 55px;
        font-size: 13px;
        text-transform: none;
        font-family: $default-font-family;
        background: #fff;
        border-radius: 20px;
        line-height: 40px;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          left: 10px;
          top: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #a1a1a1;
          background: #fff;
          margin-right: 20px;
          cursor: pointer;
          transition: all 0.3s;
          text-align: center;
          line-height: 22px;
          font-size: 14px;
          color: #a1a1a1;
          transition: all 0.3s;
        }
      }

      input[type='radio']:checked + label {
        &:before {
          background: #3482cb;
          border-color: #3482cb;
        }
      }

      input[type='radio']:disabled + label {
        color: #d8d8d8;
        cursor: default;
      }
    }

    &.input--group--checkbox {
      input[type='checkbox'] {
        width: 20px;
        height: 20px;
        position: absolute;
        left: -99999px;
      }

      label {
        position: relative;
        padding-left: 40px;
        font-size: 11px;
        text-transform: none;
        font-family: $default-font-family;
        line-height: 20px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          border: 1px solid #a1a1a1;
          background: #fff;
          margin-right: 20px;
          cursor: pointer;
          transition: all 0.3s;
          text-align: center;
          line-height: 22px;
          font-size: 14px;
          color: #a1a1a1;
        }
      }

      input[type='checkbox']:checked + label {
        &:before {
          content: 'X';
        }
      }

      &.error {
        label:before {
          border-color: red;
        }
      }
    }
  }

  .input--calendar {
    margin: 50px 0;
    @include clearfix();

    &__left {
      float: left;
      width: calc(50% - 10px);
      margin-right: 10px;
    }

    &__right {
      float: right;
      width: calc(50% - 10px);
      margin-left: 10px;
    }

    @include below(768px) {
      &__left,
      &__right {
        float: none;
        width: 100%;
        margin: 0;
        padding: 0;
      }

      &__left {
        margin-bottom: 25px;
      }
    }

    &__title {
      font-size: 13px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-family: $bold-font-family;
    }
  }

  .input--submit {
    text-align: center;

    & > div {
      margin-bottom: 25px;

      a {
        color: #000;
        font-size: 11px;
        transition: all 0.3s;

        &:hover {
          color: #3482cb;
        }
      }
    }
  }

  .selectric-wrapper {
    .selectric {
      background: #fff;
    }

    .label,
    .button {
      height: 50px;
      line-height: 50px;
    }

    .button {
      background-color: #fff;
      color: #000;

      &:after {
        border-top-color: #000;
      }
    }

    .selectric-items {
      background: #fff;

      li {
        margin: 5px;
        background: #fff;
        transition: all 0.3s;

        &.highlighted,
        &:hover {
          background: #f8f8f8;
          color: #0077cb;
        }
      }
    }
  }

  .error .selectric-wrapper {
    .selectric {
      border-color: red;
    }
  }

  .input--group--address {
    @include clearfix();

    .input--group__street {
      float: left;
      width: calc(70% - 20px);
      margin-right: 20px;
    }

    .input--group__number {
      float: left;
      width: calc(30%);
    }

    .input--group__zipcode {
      float: left;
      width: calc(30%);
    }

    .input--group__city {
      float: left;
      width: calc(70% - 20px);
      margin-left: 20px;
    }
  }

  .input--errors {
    padding: 10px;
    margin: 25px 0;
    border: 1px solid red;
    display: none;
    line-height: 25px;

    &.show {
      display: block;
    }
  }

  .input--error {
    color: red;
  }
}
