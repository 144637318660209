html.samsunggreat {

  /*
  @font-face {
    font-family: 'icomoon';
    src:
      url('fonts/icomoon.ttf?j55vuj') format('truetype'),
      url('fonts/icomoon.woff?j55vuj') format('woff'),
      url('fonts/icomoon.svg?j55vuj#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  */

  [class^="icon-icon"], [class*=" icon-icon"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-icon-check:before {
    content: "\e928";
  }
  .icon-icon-livefocus:before {
    content: "\e926";
  }
  .icon-icon-opticalzoom:before {
    content: "\e927";
  }
  .icon-icon-cart2:before {
    content: "\e915";
  }
  .icon-icon-crop2:before {
    content: "\e916";
  }
  .icon-icon-draw2:before {
    content: "\e917";
  }
  .icon-icon-filters2:before {
    content: "\e918";
  }
  .icon-icon-finger2:before {
    content: "\e919";
  }
  .icon-icon-fingerprint2:before {
    content: "\e91a";
  }
  .icon-icon-galaxy2:before {
    content: "\e91b";
  }
  .icon-icon-link2:before {
    content: "\e91c";
  }
  .icon-icon-marker2:before {
    content: "\e91d";
  }
  .icon-icon-mirror2:before {
    content: "\e91e";
  }
  .icon-icon-person2:before {
    content: "\e91f";
  }
  .icon-icon-popcorn2:before {
    content: "\e920";
  }
  .icon-icon-screen2:before {
    content: "\e921";
  }
  .icon-icon-selfie2:before {
    content: "\e922";
  }
  .icon-icon-stars2:before {
    content: "\e923";
  }
  .icon-icon-view2:before {
    content: "\e924";
  }
  .icon-icon-watch2:before {
    content: "\e925";
  }
  .icon-icon-arrow-left:before {
    content: "\e913";
  }
  .icon-icon-arrow-right:before {
    content: "\e914";
  }
  .icon-icon-play-filled:before {
    content: "\e912";
  }
  .icon-icon-cart:before {
    content: "\e900";
  }
  .icon-icon-crop:before {
    content: "\e901";
  }
  .icon-icon-draw:before {
    content: "\e902";
  }
  .icon-icon-filters:before {
    content: "\e903";
  }
  .icon-icon-finger:before {
    content: "\e904";
  }
  .icon-icon-fingerprint:before {
    content: "\e905";
  }
  .icon-icon-galaxy:before {
    content: "\e906";
  }
  .icon-icon-link:before {
    content: "\e907";
  }
  .icon-icon-marker:before {
    content: "\e908";
  }
  .icon-icon-mirror:before {
    content: "\e909";
  }
  .icon-icon-person:before {
    content: "\e90a";
  }
  .icon-icon-play:before {
    content: "\e90b";
  }
  .icon-icon-popcorn:before {
    content: "\e90c";
  }
  .icon-icon-screen:before {
    content: "\e90d";
  }
  .icon-icon-selfie:before {
    content: "\e90e";
  }
  .icon-icon-stars:before {
    content: "\e90f";
  }
  .icon-icon-view:before {
    content: "\e910";
  }
  .icon-icon-watch:before {
    content: "\e911";
  }


}
