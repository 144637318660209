html.samsunggreat {

  .button {

    display: inline-block;
    text-align: left;
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;

    &.button--icon {

      padding: 12px;
      border-radius: 100px;

      [class^="icon-"], [class*=" icon-"] {
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        font-size: 32px;
        font-size: 40px;
      }

      .button__content {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        line-height: 13px;
        font-family: $default-font-family;

        .button__title {
          font-size: 24px;
          line-height: 24px;
          font-family: $boldsharp-font-family;
          margin-bottom: 5px;
        }
      }

      @include below(768px){
        font-size: 24px;
        line-height: 30px;
      }

      @include below(400px){
        [class^="icon-"], [class*=" icon-"] {
          margin-right: 2px;
        }
      }

    }

    &.button--ghost {

      padding: 0 30px;
      letter-spacing: 2px;
      line-height: 60px;
      border-radius: 28px;
      color: #000;
      font-size: 13px;
      display: inline-block;
      border: 1px solid #000;
      text-transform: uppercase;
      font-family: $bold-font-family;
      transition: all 0.3s;
      min-width: 120px;
      text-align: center;
      background: none;

      &:hover {

        background: #1428A0;
        color: #FFF;
        border-color: #1428A0;

      }

      &.button--ghost-inversed {

        color: #FFF;
        border-color: #FFF;

        &:hover {
          background: #FFF;
          color: #000;
          border-color: #000;
        }

      }

      &.button--ghost--small {

        //padding: 10px;

      }

      @include below(420px){
        letter-spacing: 0;
        padding: 0 20px;
      }
    }


    &.button--black {
      background: #000;
      color: #FFF;
      text-transform: none;
      text-decoration: none;
      border: 2px solid #FFF;

      &:hover {
        background: #FFF;
        color: #000;
        border-color: #000;

        .button__icon {
          color: #000;
        }
      }
    }

    &.button--white {

      background: #FFF;
      color: #000;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 60px;
      text-decoration: none;
      border-radius: 100px;
      padding: 0 40px;

      &:hover {
        background: #000;
        color: #FFF;
        border-color: #FFF;
      }

      @include below(1024px){
        padding: 0 20px;
      }

    }

    &.button--blue {

      background: #0077CB;
      padding: 0 20px;
      max-width: 300px;
      color: #FFF;


    }

  }

}
